@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");
html {
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}

#root {
  width: 100vw;
}

.resizer {
  display: inline-block;
  width: 3px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
  padding: 0 10px;
}

.action-button-button:hover {
  background-color: var(--chakra-colors-gray-100);
}

.jodit-react-container {
  height: 100%;
}
